import {  gsap, TimelineMax, Power2, Bounce } from "./node_modules/gsap/all.js";


const logo=document.querySelector('.logo')
const GG=document.querySelector('.GG-Industry-Shop')
const full=document.querySelector('.FullTimeGaming')
const inCurand = document.querySelector('.inCurand')
const tl=new TimelineMax();

tl.to(logo, 0.5, {opacity: 1, ease: Power2.easeInOut,});
tl.fromTo(logo,2,{ y:-800}, { y:0, ease: "bounce.out",

    onComplete: ()=>{
        
        tl.to(GG, 1.5, {opacity: 1, ease: Power2.easeInOut,});
        tl.to(full, 1, {opacity: 1, ease: Power2.easeInOut}, "-=0.5");
        setTimeout(()=>{
            logo.classList.add('logoAnimation')
        },1500) 
        tl.to(inCurand, 1.5, {opacity: 1, ease: Power2.easeInOut,});
    }
    })


